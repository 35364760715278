<template>
  <div>
        <b-card
          img-top
          tag="article"
          style="width: 100%;"
        >
          <img class="mouse-pointer-card image-especie" :src="item.downloadUrl" alt="Espécie" title="Clique para ampliar" @click="openModalImage(item)" loading="lazy">
          <p class="especie-autor">Crédito: {{item.authorName}}</p>

          <b-card-text :class="mousePointerClass" @click="openFicha(item.fichaId)">
            <!--<p class="especie-common-name" :class="mousePointerClass" v-html="item.commonName.split(',')[0]"></p>-->
            <p class="especie-common-name" :class="mousePointerClass" v-html="capitalizeFirstLetter(item.commonNameMain)"></p>
            <p class="especie-nome" :class="mousePointerClass" v-html="item.cientificName"></p>
            <p class="category" :class="mousePointerClass" v-html="item.category"></p>
          </b-card-text>
        </b-card>
  </div>
</template>
<script>
import barramento from "@/barramento"

export default {
  props: [ 'item' ],
  data(){
    return {
      mousePointerClass: "mouse-pointer-card",
    }
  },
  created(){
    if(this.item.published){
      this.mousePointerClass = "mouse-pointer-card"
    } else {
      this.mousePointerClass = ""
    }
  },
  methods: {
    openFicha(url){
      if(this.item.published){
        barramento.$emit('openModalFicha', url)
      }
    },
    openModalImage(img){
      barramento.$emit('modal_especies_image', img)
    },
    capitalizeFirstLetter(text) {
      if (!text) return '';

      // Converte apenas a primeira letra em maiúscula e o restante permanece como está
      return text.charAt(0).toUpperCase() + text.slice(1);
    }
  }
}
</script>

<style lang="css">
  .mouse-pointer-card{
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: green;
    color: green;
  }
  .card-body {
    padding: 0 !important;
  }
  .card-text {
    padding: 30px;
    height: 100px;
  }
  .image-especie {
    object-fit: cover;
    max-height: 250px;
    border-radius: 10%;
  }
  .especie-autor {
    margin-top: 5px;
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    float: none;
  }
  .especie-nome {
    margin-top: -20px;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    float: right;
    text-decoration: none;
  }
  .especie-common-name {
    margin-top: -20px;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    float: right;
    text-decoration: none;
  }
  .category {
    margin-top: -20px;
    width: 100%;
    text-align: center;
    font-size: 0.9rem;
    float: right;
    text-decoration: none;
    font-weight: 500;
  }
  .card {
    box-shadow: none !important;
  }
@media only screen and (max-width: 575px) {
  .image-especie {
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
}

</style>